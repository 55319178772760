export const translationPath = (baseUrl, locale) =>
  `${baseUrl}assets/locale/productWidget/productWidget_${locale}.json`;

export const enum FormFactor {
  Desktop = 'Desktop',
  Mobile = 'Mobile',
  Tablet = 'Tablet',
}

export const origin = 'product-widget';

export enum SPECS {
  URL_CUSTOMIZE = 'specs.stores.EnableUrlCustomizeInProductPage',
  EDITABLE_HEADING_TAGS = 'specs.stores.EditableHeadingTags',
  PRICE_RANGE_VIEWER = 'specs.stores.PriceRangeViewer',
  BASE_PRICE_IN_GALLERY = 'specs.stores.BasePriceInGallery',
}

export const MULTILINGUAL_TO_TRANSLATIONS_MAP = {
  NAVIGATE_TO_PRODUCT_PAGE_BUTTON: 'PRODUCT_WIDGET_BUTTON_TEXT',
  ADD_TO_CART_BUTTON: 'PRODUCT_WIDGET_BUTTON_TEXT',
  OUT_OF_STOCK: 'PRODUCT_WIDGET_BUTTON_OOS_TEXT',
};

export enum Experiments {}
